html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #af646f;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  text-decoration: none;
}

@media only screen and (max-width: 920px) {
  body{
    font-size: 12px;
  }
}

.swiper-section {
  max-width: 900px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.swiper-slide img {
  width: 100%;
  max-height: 600px;
}

.swiper-slide {
  width: 50% !important;
}

.swiper-button-prev, .swiper-button-next {
  color: #af646f !important;
}

@media (max-width: 500px) {
  .swiper-slide img {
    width: 100%;
    max-height: 250px !important;
  }
}