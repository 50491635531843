#side-text{
    flex-basis:40%;
    display: flex;
    flex-direction: column;
}

#side-text h1{
    font-size: 3em;
    margin-block-end: 0.4em;
}

#side-text p{
    font-size: 1.5em;
}

@media only screen and (max-width: 800px) {
    #side-text{
        flex-basis: 100%;
        text-align: center;
    }
}