.question{
    flex-basis: 70%;
    background-color: white;
    border: 0;
    text-align: left;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: 0.4s;
    cursor: pointer;
    color: #af646f !important;
}

.question:hover{
    transform: scale(1.02);
}


@media only screen and (max-width: 800px) {
    .question{
        flex-basis: 90%;
    }
}